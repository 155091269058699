import { Chip, ChipVariants } from "components/Chip";
import { orderStatusConstants } from "constants/order";
import { ToastContext } from "context/ToastContext";
import { parseDate } from "helpers/date";
import { parsePrice, centsToDollars, dollarsToCents } from "helpers/price";
import { sizer } from "layout/styles/styled/sizer";
import { Label } from "layout/typography/Label";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { useEffect, useState, useContext, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useUpdateOrderMutation } from "store/queries/order";
import styled from "styled-components";
import { IPayment, IPaymentPlan, IInstallment } from "types/Payment";

interface IPaymentDetailsEdit {
  paymentPlanMinDownPaymentPercent: number;
  paymentPlanDuration: number;
  paymentPlanRemainingAmount: number | null;
  installments?: IInstallment[];
}

const StyledCard = styled(Card)`
  margin-bottom: ${sizer(2)};
  flex-grow: 1;
`;

function PaymentDetails({
  payments,
  paymentPlan,
  orderId,
  onUpdateOrder,
  editable,
  showPaymentPlan,
  orderStatus,
}: {
  payments?: IPayment[];
  paymentPlan: IPaymentPlan;
  orderId: string;
  onUpdateOrder: () => void;
  editable: boolean;
  showPaymentPlan: boolean;
  orderStatus: string;
}) {
  const [editing, setEditing] = useState(false);
  const { control, getValues, setValue } =
    useFormContext<IPaymentDetailsEdit>();
  const [updateOrder] = useUpdateOrderMutation();
  const { current: toastElement } = useContext(ToastContext);

  useEffect(() => {
    setValue(
      "paymentPlanMinDownPaymentPercent",
      paymentPlan?.paymentPlanMinDownPaymentPercent
    );
    setValue("paymentPlanDuration", paymentPlan?.paymentPlanDuration);
    setValue(
      "paymentPlanRemainingAmount",
      paymentPlan?.remainingAmountInCents
        ? centsToDollars(paymentPlan?.remainingAmountInCents)
        : null
    );
  }, [paymentPlan, setValue]);

  const resetForm = () => {
    setValue(
      "paymentPlanMinDownPaymentPercent",
      paymentPlan?.paymentPlanMinDownPaymentPercent
    );
    setValue("paymentPlanDuration", paymentPlan?.paymentPlanDuration);
    setValue(
      "paymentPlanRemainingAmount",
      paymentPlan?.remainingAmountInCents
        ? centsToDollars(paymentPlan?.remainingAmountInCents)
        : null
    );
  };

  const paidPayment: IPayment | undefined = payments?.find(
    (aPayment) => aPayment.status === "Paid"
  );
  const refundedPayment: IPayment | undefined = payments?.find(
    (aPayment) => aPayment.status === "Refunded"
  );

  const mapPaymentDetailsUpdateRequest = (values: IPaymentDetailsEdit) => {
    return {
      paymentPlan: [
        {
          remainingAmountInCents:
            values.paymentPlanRemainingAmount !== null &&
            values.paymentPlanRemainingAmount !== undefined
              ? dollarsToCents(values.paymentPlanRemainingAmount)
              : null,
          paymentPlanDuration: values.paymentPlanDuration || 0,
          paymentPlanMinDownPaymentPercent:
            values.paymentPlanMinDownPaymentPercent,
          active: true,
          installments: paymentPlan?.installments,
        },
      ],
    };
  };

  const submit = () => {
    const values = mapPaymentDetailsUpdateRequest(getValues());
    updateOrder({ id: orderId, ...values })
      .unwrap()
      .then(() => {
        onUpdateOrder();
        toastElement?.show({
          summary: "Success!",
          severity: "success",
          detail: "Your order has been updated.",
        });
        setEditing(false);
      })
      .catch(() => {
        toastElement?.show({
          severity: "error",
          detail: "Try again later.",
          summary: "Something went wrong.",
        });
      });
  };
  const actionFooter = () => {
    return (
      <div className="w-full">
        <Button label="Save" icon="pi pi-check" onClick={submit} />
        <Button
          label="Cancel"
          onClick={() => {
            resetForm();
            setEditing(false);
          }}
          icon="pi pi-times"
          style={{ marginLeft: "0.5em" }}
        />
      </div>
    );
  };
  const actionHeader = () => {
    return (
      <Button
        label="Edit"
        icon="pi pi-check"
        onClick={() => setEditing(true)}
      />
    );
  };

  const remainingAmountDisabled = useMemo(() => {
    if (orderStatus === orderStatusConstants.SENT_TO_PATIENT) {
      return true;
    }
    return !editing;
  }, [orderStatus, editing]);
  return (
    <StyledCard
      title="Payment details"
      header={
        refundedPayment && (
          <div className="flex justify-content-end pt-2 pr-3">
            <Chip variant={ChipVariants.WARNING}>Refunded</Chip>
          </div>
        )
      }
    >
      <div className="formgrid grid w-100">
        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="paymentDate">Payment Date</Label>

          <InputText
            disabled
            id="paymentDate"
            aria-labelledby="paymentDate"
            value={
              paidPayment?.createdAt ? parseDate(paidPayment.createdAt) : "-"
            }
          />
        </div>

        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="paymentAmount">Payment Amount</Label>

          <InputText
            disabled
            id="paymentAmount"
            aria-labelledby="paymentAmount"
            value={
              paidPayment?.amountInCents
                ? parsePrice(centsToDollars(paidPayment.amountInCents))
                : "-"
            }
          />
        </div>

        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="depositDate">Deposit Date</Label>

          <InputText
            disabled
            id="depositDate"
            aria-labelledby="depositDate"
            value={
              paidPayment?.stripePayout?.payoutArrivalDate
                ? parseDate(paidPayment.stripePayout.payoutArrivalDate)
                : "-"
            }
          />
        </div>

        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="paymentMethod">Payment Method</Label>

          <InputText
            disabled
            id="paymentMethod"
            value={paidPayment?.type || "-"}
            aria-labelledby="paymentMethod"
          />
        </div>

        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="paymentStatus">Status</Label>

          <InputText
            disabled
            id="paymentStatus"
            value={paidPayment?.status || "-"}
            aria-labelledby="paymentStatus"
          />
        </div>
      </div>
      {showPaymentPlan && (
        <div className="flex flex-column gap-2">
          <div className="flex justify-content-between w-full">
            <p className="font-bold text-lg mb-3">Payment Plan</p>
            <div style={{ height: "46px" }}>
              {!editing && editable && actionHeader()}
            </div>
          </div>
          <div className="formgrid grid w-100">
            <div className="field flex flex-column col-12 md:col-4">
              <Label htmlFor="paymentPlanMinDownPaymentPercent">
                Minimum Down Payment
              </Label>
              <Controller
                data-testid="paymentPlanMinDownPaymentPercent"
                name="paymentPlanMinDownPaymentPercent"
                control={control}
                render={({ field: { value } }) => (
                  <InputNumber
                    disabled
                    value={value}
                    suffix="%"
                    inputId="paymentPlanMinDownPaymentPercent"
                    data-testid="paymentPlanMinDownPaymentPercent"
                  />
                )}
              />
            </div>
            <div className="field flex flex-column col-12 md:col-4">
              <Label htmlFor="paymentPlanDuration">Duration</Label>
              <Controller
                data-testid="paymentPlanDuration"
                name="paymentPlanDuration"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <InputNumber
                    disabled={!editing}
                    id="paymentPlanDuration"
                    aria-labelledby="paymentPlanDuration"
                    value={value}
                    onValueChange={onChange}
                    min={0}
                  />
                )}
              />
            </div>
            <div className="field flex flex-column col-12 md:col-4">
              <Label htmlFor="paymentPlanRemainingAmount">
                Remaining Amount
              </Label>
              <Controller
                data-testid="paymentPlanRemainingAmount"
                name="paymentPlanRemainingAmount"
                control={control}
                render={({ field: { value, onChange } }) =>
                  remainingAmountDisabled && !value ? (
                    <InputText value="-" disabled />
                  ) : (
                    <InputNumber
                      disabled={remainingAmountDisabled}
                      value={value}
                      currency="USD"
                      locale="en-US"
                      mode="currency"
                      inputId="paymentPlanRemainingAmount"
                      data-testid="paymentPlanRemainingAmount"
                      onValueChange={onChange}
                      min={0}
                    />
                  )
                }
              />
            </div>
          </div>
          {editing && actionFooter()}
        </div>
      )}
    </StyledCard>
  );
}

export default PaymentDetails;
