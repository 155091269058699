import { FieldValues } from "react-hook-form";

export const mapFormToRequestBody = async (props: FieldValues) => ({
  name: props.hospitalName,
  address: `${props.addressLine1}${
    props.addressLine2 === "" ? "" : ` ${props.addressLine2}`
  }`,
  city: props.city,
  state: props.state,
  zipcode: Number(props.zipcode),
  rcm: props.rcm,
  specialty: props.specialty,
  contactName: props.contactName,
  reportType: props.reportType,
  unitHospitalNumber: props.unitHospitalNumber,
  contactPhone: props.contactPhone,
  orderType: props.orderType,
  taxId: props.taxId,
  emailToReceiveReports: props.emailToReceiveReports,
  connectAccountId: props.connectAccountId,
  messageSender: props.messageSender,
  emailSender: props.emailSender,
  emailToReceiveNotification: props.emailToReceiveNotification,
  frontDeskEmployeesToReceiveDailyEmail:
    props.frontDeskEmployeesToReceiveDailyEmail,
  emailTemplateAttempt1: props.emailTemplateAttempt1,
  attempt1: props.attempt1,
  emailTemplateAttempt2: props.emailTemplateAttempt2,
  attempt2: props.attempt2,
  emailTemplateAttempt3: props.emailTemplateAttempt3,
  attempt3: props.attempt3,
  bundleEmailTemplateAttempt1: props.emailTemplateAttemptBundle1,
  bundleAttempt1: props.attemptBundle1,
  bundleEmailTemplateAttempt2: props.emailTemplateAttemptBundle2,
  bundleAttempt2: props.attemptBundle2,
  bundleEmailTemplateAttempt3: props.emailTemplateAttemptBundle3,
  bundleAttempt3: props.attemptBundle3,
  emailTemplateAttemptED1: props.emailTemplateAttemptED1,
  emailTemplateAttemptED2: props.emailTemplateAttemptED2,
  emailTemplateAttemptED3: props.emailTemplateAttemptED3,
  attemptED1: props.attemptED1,
  attemptED2: props.attemptED2,
  attemptED3: props.attemptED3,
  ptFeeMethod: props.prFeeMethod,
  clearFeeForClearPackage: Number(props.clearFeeForClearPackage),
  clearTransactionFeeForPR: Number(props.clearTransactionFeeForPR),
  patientResponsibilityDiscount: Number(props.patientResponsibilityDiscount),
  clearFinancingFee: Number(props.clearFinancingFee),
  clearHealthFeeForGFE: Number(props.clearHealthFeeForGFE),
  clearHealthDiscountForGFE: Number(props.clearHealthDiscountForGFE),
  accountLogoUrl: props.accountLogoUrl,
  contactEmail: props.contactEmail,
  expirationDays: Number(props.expirationDays),
  attemptBariatrics1: props.attemptBariatrics1,
  attemptBariatrics2: props.attemptBariatrics2,
  attemptBariatrics3: props.attemptBariatrics3,
  emailTemplateAttemptBariatrics1: props.emailTemplateAttemptBariatrics1,
  emailTemplateAttemptBariatrics2: props.emailTemplateAttemptBariatrics2,
  emailTemplateAttemptBariatrics3: props.emailTemplateAttemptBariatrics3,
  attemptGFE1: props.attemptGFE1,
  attemptGFE2: props.attemptGFE2,
  attemptGFE3: props.attemptGFE3,
  emailTemplateAttemptGFE1: props.emailTemplateAttemptGFE1,
  emailTemplateAttemptGFE2: props.emailTemplateAttemptGFE2,
  emailTemplateAttemptGFE3: props.emailTemplateAttemptGFE3,
  publicContactName: props.publicContactName,
  publicContactRole: props.publicContactRole,
  publicContactPhone: props.publicContactPhone,
  eligibilityEnabled: Boolean(props.eligibilityEnabled),
  npi: props.npiNumber,
  paymentPlanTypeEnabled: {
    patientResponsibility: Boolean(props.patientResponsibilityEnabled),
    ed: Boolean(props.edEnabled),
    bariatrics: Boolean(props.bariatricsEnabled),
    gfe: Boolean(props.gfeEnabled),
    bundled: Boolean(props.bundledEnabled),
  },
  paymentPlanDuration: props.paymentPlanDuration,
  paymentPlanMinDownPaymentPercent: props.paymentPlanMinDownPaymentPercent,
});
