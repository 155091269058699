import { useAuth0 } from "@auth0/auth0-react";
import { ItemBar, ItemBarLeft, ItemBarRight } from "components/ItemBar";
import { LoadingSpinner } from "components/LoadingSpinner";
import { PageDataTable } from "components/PageDataTable";
import {
  orderStatusConstants,
  orderStatusMapping,
  orderTypeConstants,
  orderTypeMapping,
} from "constants/order";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { parseDate } from "helpers/date";
import { centsToDollars, parsePrice } from "helpers/price";
import usePrice from "hooks/usePrice";
import { useQuery } from "hooks/useQuery";
import { sizer } from "layout/styles/styled/sizer";
import { useFilteredOrders } from "pages/Orders/hooks";
import { Button } from "primereact/button";
import { Calendar, CalendarValueType } from "primereact/calendar";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useGetOrdersQuery } from "store/queries/order";
import styled from "styled-components";

dayjs.extend(utc);

const StyledButton = styled(Button)`
  gap: ${sizer(2)};
`;

const STATUS_OPTIONS = [
  {
    value: orderStatusConstants.CANCELED,
    name: orderStatusMapping[orderStatusConstants.CANCELED],
  },
  {
    value: orderStatusConstants.DRAFT,
    name: orderStatusMapping[orderStatusConstants.DRAFT],
  },
  {
    value: orderStatusConstants.EXPIRED,
    name: orderStatusMapping[orderStatusConstants.EXPIRED],
  },
  {
    value: orderStatusConstants.PAID,
    name: orderStatusMapping[orderStatusConstants.PAID],
  },
  {
    value: orderStatusConstants.REFUNDED,
    name: orderStatusMapping[orderStatusConstants.REFUNDED],
  },
  {
    value: orderStatusConstants.SENT_TO_PATIENT,
    name: orderStatusMapping[orderStatusConstants.SENT_TO_PATIENT],
  },
  {
    value: orderStatusConstants.PARTIALLY_PAID,
    name: orderStatusMapping[orderStatusConstants.PARTIALLY_PAID],
  },
];

const ORDER_TYPE_OPTIONS = [
  {
    value: orderTypeConstants.BUNDLED,
    name: orderTypeMapping[orderTypeConstants.BUNDLED],
  },
  {
    value: orderTypeConstants.ED,
    name: orderTypeMapping[orderTypeConstants.ED],
  },
  {
    value: orderTypeConstants.PATIENT_RESPONSIBILITY,
    name: orderTypeMapping[orderTypeConstants.PATIENT_RESPONSIBILITY],
  },
  {
    value: orderTypeConstants.BARIATRICS,
    name: orderTypeMapping[orderTypeConstants.BARIATRICS],
  },
  {
    value: orderTypeConstants.GFE,
    name: orderTypeMapping[orderTypeConstants.GFE],
  },
];

export function OrdersTable({ accountId }: { accountId?: string }) {
  const { getClearPriceByOrderType } = usePrice();
  const { loginWithRedirect } = useAuth0();
  const query = useQuery();

  const { data: orders, isLoading, isError } = useGetOrdersQuery(accountId);
  const navigate = useNavigate();
  const location = useLocation();

  const [filters, setFilters] = useState({
    status: [] as string[],
    orderType: [] as string[],
    patientName: "",
    dateOfService: [] as Date[],
  });

  const transformSearchParam = (value: string | null, isDate = false) => {
    if (!value || value === "") return [];
    if (isDate) {
      return value
        .trim()
        .split(",")
        .map((item) => new Date(item.trim()));
    }
    return value
      .trim()
      .split(",")
      .map((item) => item.trim());
  };

  useEffect(() => {
    const status = transformSearchParam(query.get("status")) as string[];
    const orderType = transformSearchParam(query.get("orderType")) as string[];
    const patientName = query.get("patientName") || "";
    const dateOfService = transformSearchParam(
      query.get("dateOfService"),
      true
    ) as Date[] | [];
    setFilters({ status, orderType, patientName, dateOfService });
  }, []);

  useEffect(() => {
    if (isError) loginWithRedirect();
  }, [isError]);

  const parsedOrders = useMemo(() => {
    if (!orders) return [];

    const { data: ordersToParse } = orders;

    return ordersToParse.map((order) => {
      const { clearPrice } = getClearPriceByOrderType({
        order,
      });

      return {
        orderId: order.id,
        status: order.status,
        orderType: order.orderType,
        orderAmount: clearPrice,
        dateOfService: parseDate(order.dateOfService),
        patientName: `${order.patient.firstName} ${order.patient.lastName}`,
      };
    });
  }, [orders]);

  const filteredOrders = useFilteredOrders({ orders: parsedOrders, filters });

  function handleOnChangeFilter({
    newValue,
    field,
  }: {
    newValue: CalendarValueType | string;
    field: "patientName" | "status" | "orderType" | "dateOfService";
  }) {
    setFilters((prevData) => ({ ...prevData, [field]: newValue }));
    let valueToSet = "";
    if (newValue instanceof Date) {
      valueToSet = newValue.toISOString();
    } else if (Array.isArray(newValue)) {
      if (newValue.every((item) => item instanceof Date)) {
        valueToSet = newValue.map((date) => date.toISOString()).join(",");
      } else if (newValue.every((item) => typeof item === "string")) {
        valueToSet = newValue.join(",");
      } else if (
        newValue.every((item) => item === null || item instanceof Date)
      ) {
        valueToSet = newValue
          .filter((item) => item instanceof Date) // Only convert Date objects
          .map((date) => date.toISOString())
          .join(",");
      }
    } else if (typeof newValue === "string") {
      valueToSet = newValue;
    } else {
      valueToSet = "";
    }
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(field, valueToSet);
    navigate(`?${searchParams.toString()}`, { replace: true });
  }

  const onClick = (row: any) => {
    const currentQueryParams = new URLSearchParams(location.search);
    if (row.status === orderStatusConstants.DRAFT && accountId) {
      currentQueryParams.set("accountId", accountId);
      const queryString = `?${currentQueryParams.toString()}`;
      // NAVIGATE TO THE REVIEW PAGE WITH THE DRAFT ORDER DATA
      navigate(`/orders/review/${row.orderId}${queryString}`);
      return;
    }
    const queryString = `?${currentQueryParams.toString()}`;
    navigate(`/orders/${row.orderId}${queryString}`);
  };

  function orderAction(row: any) {
    return (
      <Button
        className="p-button-link"
        data-testid="order_action_OrderTable"
        onClick={() => onClick(row)}
      >
        <p className="small-text">{`${
          row.status === orderStatusConstants.DRAFT ? "Review" : "View"
        } Order`}</p>
      </Button>
    );
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <ItemBar>
        <ItemBarLeft>
          <InputText
            data-testid="name_multiselect_OrderTable"
            value={filters.patientName}
            placeholder="Search for Patient"
            onChange={(e) =>
              handleOnChangeFilter({
                newValue: e.target.value,
                field: "patientName",
              })
            }
          />
          <MultiSelect
            data-testid="type_multiselect_OrderTable"
            display="chip"
            optionLabel="name"
            placeholder="Order type"
            value={filters.orderType}
            options={ORDER_TYPE_OPTIONS}
            onChange={(e) =>
              handleOnChangeFilter({
                newValue: e.target.value,
                field: "orderType",
              })
            }
          />
          <Calendar
            data-testid="range_calendar_OrderTable"
            selectionMode="range"
            placeholder="Date of Service"
            value={filters.dateOfService}
            onChange={(e) => {
              handleOnChangeFilter({
                field: "dateOfService",
                newValue: e.target.value || [],
              });
            }}
          />
          <MultiSelect
            data-testid="status_multiselect_OrderTable"
            display="chip"
            optionLabel="name"
            placeholder="Status"
            value={filters.status}
            options={STATUS_OPTIONS}
            onChange={(e) =>
              handleOnChangeFilter({
                newValue: e.target.value,
                field: "status",
              })
            }
          />
        </ItemBarLeft>
        <ItemBarRight>
          <StyledButton
            data-testid="create_new_order_OrderTable"
            icon="pi pi-plus"
            className="p-button-raised"
            onClick={() => navigate(`/orders/new?accountId=${accountId}`)}
          >
            Create new order
          </StyledButton>
        </ItemBarRight>
      </ItemBar>

      <PageDataTable
        emptyMessage="No orders found."
        value={filteredOrders}
        onRowClick={({ data, originalEvent }) =>
          originalEvent.detail > 1 && onClick(data)
        }
      >
        <Column field="patientName" header="Patient Name" />
        <Column
          field="orderType"
          header="Order Type"
          body={(row) => orderTypeMapping[row.orderType]}
        />
        <Column field="dateOfService" header="Date of Service" />
        <Column
          field="status"
          header="Status"
          body={(row) => orderStatusMapping[row.status]}
        />
        <Column
          field="orderAmount"
          header="Clear Price for patient"
          body={(row) => parsePrice(centsToDollars(row.orderAmount))}
        />
        <Column header="" field="viewOrder" body={(row) => orderAction(row)} />
      </PageDataTable>
    </div>
  );
}
