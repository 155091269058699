import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
} from "@react-pdf/renderer";
import { LoadingSpinner } from "components/LoadingSpinner";
import { orderTypeConstants } from "constants/order";
import { parseDate } from "helpers/date";
import { getFeeTypeForOrder } from "helpers/fee";
import {
  calculateClearCharge,
  centsToDollars,
  parsePrice,
} from "helpers/price";
import useDocumentTitle from "hooks/useDocumentTitle";
import usePrice from "hooks/usePrice";
import { useQuery } from "hooks/useQuery";
import { sizer } from "layout/styles/styled/sizer";
import { isEmpty, sumBy } from "lodash";
import { Button } from "primereact/button";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useGetPublicOrderQuery } from "store/queries/order";
import { orderSelector } from "store/slices/order";
import styled from "styled-components";
import {
  IDefaultProcedure,
  IDefaultServiceDetails,
  IOrder,
  IPatientResponsibilityProcedure,
} from "types/Order/Order";
import { IPayment } from "types/Payment";

const hospitalGeneralDefault =
  "https://assets.dev.price.clearhealthinc.com/hospital_logo.png";

const clearLogo =
  "https://assets.dev.price.clearhealthinc.com/clearhealthLogo.png";

type UniqueProcedureList = IDefaultProcedure | IPatientResponsibilityProcedure;

const StyledButton = styled(Button)`
  gap: ${sizer(2)};
  margin-bottom: ${sizer(4)};
  align-self: flex-start;

  span:first-child {
    font-size: ${sizer(3)};
  }
`;

export function Receipt({ order }: { order?: IOrder }) {
  useDocumentTitle("Receipt View");
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#DAE8FF",
      fontFamily: "Lato",
      display: "flex",
      justifyContent: "space-between",
    },
    section: {
      margin: "16px 20px",
      fontFamily: "Lato",
      padding: "28px 36px",
      borderRadius: "10px",
      backgroundColor: "#FFF",
    },
    title: {
      fontSize: "12px",
      color: "#0D0D0D",
      fontWeight: 800,
      marginBottom: "16px",
    },
    text: {
      color: "#666",
      fontWeight: 400,
      fontSize: "12px",
    },
    list: {
      borderBottom: "1px solid #D9D9D9",
    },
    itemList: {
      color: "#666",
      fontWeight: 400,
      fontSize: "12px",
      marginBottom: "16px",
    },
    listTitle: {
      fontWeight: 400,
      fontSize: "12px",
      marginBottom: "16px",
    },
    containerOrderInfo: {
      display: "flex",
      flexDirection: "row",
      marginBottom: "16px",
      paddingBottom: "16px",
      justifyContent: "space-between",
      borderBottom: "1px solid #D9D9D9",
    },
    bundlePriceContainerTitle: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "16px",
    },
    bundlePriceTitle: {
      color: "#000",
      fontSize: "12px",
      fontWeight: 600,
      marginBottom: "6px",
    },
    bundlePriceDescription: {
      color: "#999999",
      fontSize: "12px",
    },
    bundlePriceContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: "16px",
      paddingBottom: "16px",
      borderBottom: "1px solid #D9D9D9",
    },
    bundlePrice: {
      color: "#5B4DFD",
      fontSize: "14px",
      fontWeight: 600,
    },
    paymentAmountContainer: {
      display: "flex",
      marginBottom: "10px",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    paymentAmountTitle: {
      color: "#000",
      fontWeight: 600,
      fontSize: "12px",
    },
    paymentAmount: {
      color: "#000",
      fontWeight: 600,
      fontSize: "14px",
    },
    infoTitle: {
      color: "#666666",
      fontSize: "12px",
      marginBottom: "8px",
    },
    pageDescription: {
      fontSize: "12px",
      margin: "16px 28px 4px 28px",
      marginTop: "16px",
      lineHeight: "1.5px",
      textAlign: "center",
    },
    infoDescription: {
      color: "#000",
      fontSize: "12px",
    },
    tipsContainer: {
      backgroundColor: "#DAE8FF",
      borderRadius: "10px",
      padding: "12px",
      marginTop: "16px",
    },
    tipsContainerTitle: {
      fontWeight: 600,
      fontSize: "12px",
      marginBottom: "8px",
      color: "#0D0D0D",
    },
    tipsContainerDescription: {
      color: "#0D0D0D",
      fontSize: "12px",
    },
    header: {
      width: "100%",
      height: "80px",
      backgroundColor: "#fff",
      display: "flex",
      justifyContent: "center",
    },
    image: {
      width: "80px",
      marginTop: "4px",
      marginLeft: "16px",
    },
    footer: {
      height: "112px",
      backgroundColor: "#fff",
      padding: "8px 0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    footerText: {
      fontSize: "12px",
      marginBottom: "8px",
      color: "#9DA5B1",
    },
    footerImage: {
      height: "40px",
      marginBottom: "8px",
      width: "80px",
    },
  });

  Font.register({
    family: "Lato",
    src: "https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh7USSwaPGQ3q5d0N7w.woff2",
  });

  const { id } = useParams();
  const { data, isLoading } = useGetPublicOrderQuery(id || "", {
    skip: !!order,
    refetchOnMountOrArgChange: true,
  });
  const { getClearPriceByOrderType } = usePrice();
  const { accountInfo } = useSelector(orderSelector);
  const [logoUrl, setLogoUrl] = useState<string>(hospitalGeneralDefault);
  const query = useQuery();
  const internalUser = query.get("internalUser");
  const navigate = useNavigate();

  const orderInfo = useMemo(() => {
    if (order) {
      return order;
    }

    return data?.data || ({} as IOrder);
  }, [data, order]);

  useEffect(() => {
    const logoUrl =
      accountInfo?.accountLogoUrl || orderInfo?.account?.accountLogoUrl;

    if (logoUrl) {
      setLogoUrl(logoUrl);
    }
  }, [accountInfo, orderInfo]);

  const clearFee =
    orderInfo?.account?.accountFees.find(
      (fee: any) => fee.type === getFeeTypeForOrder(orderInfo.orderType)
    )?.valueInPercentage || 0;

  const { clearPrice, total, totalSaving } = getClearPriceByOrderType({
    order: orderInfo,
  });

  const isPatientResponsibility =
    orderInfo?.orderType === orderTypeConstants.PATIENT_RESPONSIBILITY;

  const isClearEstimate = orderInfo?.orderType === orderTypeConstants.GFE;

  const clearCharge = calculateClearCharge(clearPrice, Number(clearFee));

  const paymentInfo =
    orderInfo?.payments && orderInfo.payments.length
      ? orderInfo.payments.find((aPayment) => aPayment.status === "Paid")
      : ({} as IPayment);

  const uniqueProcedureList = useMemo(() => {
    if (isEmpty(orderInfo)) return [];

    const { procedures } = orderInfo.serviceDetails;

    const uniqueArray = [] as unknown as UniqueProcedureList[];
    const seenValues = new Set();

    procedures?.forEach((item) => {
      const valueToCheck = item.code;

      if (!seenValues.has(valueToCheck)) {
        seenValues.add(valueToCheck);
        uniqueArray.push(item);
      }
    });

    return uniqueArray;
  }, [orderInfo.serviceDetails]);

  const providersListForBundledPricing = useMemo(() => {
    if (isEmpty(orderInfo)) return [];

    const { procedures } = orderInfo?.serviceDetails as IDefaultServiceDetails;

    const proceduresParsed: {
      providerName: string;
      amountInCents: number;
    }[] = [];
    const providerMap = new Map();

    // eslint-disable-next-line no-restricted-syntax
    for (const item of procedures) {
      const { providerName, amountInCents } = item;

      if (providerMap.has(providerName)) {
        providerMap.set(
          providerName,
          providerMap.get(providerName) + amountInCents
        );
      } else {
        providerMap.set(providerName, amountInCents);
      }
    }

    providerMap.forEach((amountInCents, providerName) => {
      proceduresParsed.push({ providerName, amountInCents });
    });

    return proceduresParsed;
  }, [orderInfo.serviceDetails]);

  const providersListForGfePricing = useMemo(() => {
    if (isEmpty(orderInfo)) return [];
    const bundledProviderAmounts =
      orderInfo?.amountBreakdown?.bundledProviderAmounts || [];
    const providerAmountsParsed: {
      providerName: string;
      amountInCents: number;
    }[] = [];
    const providerMap = new Map();
    // eslint-disable-next-line no-restricted-syntax
    for (const item of bundledProviderAmounts) {
      const { providerName, expectedTransferAmount } = item;

      if (providerMap.has(providerName)) {
        providerMap.set(
          providerName,
          providerMap.get(providerName) + expectedTransferAmount
        );
      } else {
        providerMap.set(providerName, expectedTransferAmount);
      }
    }

    providerMap.forEach((amountInCents, providerName) => {
      providerAmountsParsed.push({ providerName, amountInCents });
    });

    return providerAmountsParsed;
  }, [orderInfo?.amountBreakdown]);

  const providersList = useMemo(() => {
    if (isClearEstimate) {
      return providersListForGfePricing;
    }
    return providersListForBundledPricing;
  }, [
    isClearEstimate,
    providersListForGfePricing,
    providersListForBundledPricing,
  ]);

  const clearChargeBundledOrder = parsePrice(
    centsToDollars(
      clearPrice - sumBy(providersListForBundledPricing, "amountInCents")
    )
  );

  const isFinancingPaymentMethod = paymentInfo?.type === "Financing";

  const showPaymentPlanInfo = useMemo(() => {
    if (!data) return false;
    const astivePaymentPlan = orderInfo.paymentPlan.find((plan) => plan.active);
    return !!astivePaymentPlan?.installments?.length;
  }, [orderInfo]);

  const paymentPlanInfo = useMemo(() => {
    if (!data) return null;
    const astivePaymentPlan = orderInfo.paymentPlan.find((plan) => plan.active);
    const totalPaidAmount =
      orderInfo?.payments
        .filter((aPayment) => aPayment.status === "Paid")
        .reduce((sum, aPayment) => sum + (aPayment.amountInCents || 0), 0) || 0;
    return { ...astivePaymentPlan, totalPaidAmount };
  }, [orderInfo]);

  if (isLoading || !orderInfo) return <LoadingSpinner />;

  const prices = () => {
    if (!isFinancingPaymentMethod) {
      return isPatientResponsibility ? (
        <View style={styles.bundlePriceContainerTitle}>
          <Text style={styles.bundlePriceTitle}>{orderInfo?.account.name}</Text>

          <Text style={styles.bundlePrice}>
            {parsePrice(centsToDollars(clearPrice - clearCharge))}
          </Text>
        </View>
      ) : (
        providersList.map((provider, index) => (
          <View
            style={styles.bundlePriceContainerTitle}
            key={`${provider.providerName}_${index}`}
          >
            <Text style={styles.bundlePriceTitle}>{provider.providerName}</Text>

            <Text style={styles.bundlePrice}>
              {parsePrice(centsToDollars(provider.amountInCents))}
            </Text>
          </View>
        ))
      );
    }
    return null;
  };

  const receipt = (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <View style={styles.header}>
            <Image
              style={styles.image}
              src={{
                body: "",
                method: "GET",
                uri: logoUrl,
                headers: {},
              }}
            />
          </View>

          {paymentInfo?.type !== "Financing" && (
            <Text style={styles.pageDescription}>
              Thank you for your payment! You saved{" "}
              {parsePrice(centsToDollars(totalSaving))} by using Clear Health
              base on the estimated out of pocket costs for your visits.
            </Text>
          )}

          <View style={styles.section}>
            <Text style={styles.title}>Visit Details</Text>

            <View style={styles.containerOrderInfo}>
              <Text style={styles.text}>
                Patient:{" "}
                {`${orderInfo?.patient.firstName} ${orderInfo?.patient.lastName}`}
              </Text>

              <Text style={styles.text}>
                Visit Date: {parseDate(orderInfo?.dateOfService)}
              </Text>
            </View>

            <View style={styles.list}>
              <Text style={styles.listTitle}>Services provided</Text>

              {uniqueProcedureList.map((currentProcedure) => (
                <Text style={styles.itemList} key={currentProcedure.code}>
                  {currentProcedure.code} - {currentProcedure.cptDescription}
                </Text>
              ))}
            </View>

            <View style={styles.bundlePriceContainer}>
              {prices()}

              {paymentInfo?.type !== "Financing" && (
                <View style={styles.bundlePriceContainerTitle}>
                  <Text style={styles.bundlePriceTitle}>
                    Clear Service Charge
                  </Text>

                  <Text style={styles.bundlePrice}>
                    {isPatientResponsibility || isClearEstimate
                      ? parsePrice(centsToDollars(clearCharge))
                      : clearChargeBundledOrder}
                  </Text>
                </View>
              )}

              <View style={styles.bundlePriceContainerTitle}>
                <Text style={styles.bundlePriceTitle}>
                  Total Payment Amount
                </Text>

                <Text style={styles.bundlePrice}>
                  {showPaymentPlanInfo &&
                    paymentPlanInfo &&
                    parsePrice(centsToDollars(paymentPlanInfo.totalPaidAmount))}
                  {!showPaymentPlanInfo &&
                    parsePrice(
                      centsToDollars(
                        !isFinancingPaymentMethod ? clearPrice : total
                      )
                    )}
                </Text>
              </View>
              {showPaymentPlanInfo && paymentPlanInfo && (
                <View style={styles.bundlePriceContainerTitle}>
                  <Text style={styles.bundlePriceTitle}>Amount Due</Text>

                  <Text style={styles.bundlePrice}>
                    {parsePrice(
                      centsToDollars(
                        paymentPlanInfo?.remainingAmountInCents || 0
                      )
                    )}
                  </Text>
                </View>
              )}
            </View>

            <Text style={styles.infoTitle}>
              Payment date:
              <Text style={styles.infoDescription}>
                {" "}
                {paymentInfo?.createdAt
                  ? parseDate(paymentInfo?.createdAt as string)
                  : "-"}
              </Text>
            </Text>

            <Text style={styles.infoTitle}>
              Payment method:
              <Text style={styles.infoDescription}>
                {" "}
                {paymentInfo?.type || "-"}
              </Text>
            </Text>
          </View>
        </View>

        <View style={styles.footer}>
          <Image
            style={styles.footerImage}
            src={{
              body: "",
              headers: {},
              method: "GET",
              uri: clearLogo,
            }}
          />

          <Text style={styles.footerText}>www.clearhealthinc.com</Text>
          <Text style={styles.footerText}>
            888-918-2522 | support@clearhealthinc.com
          </Text>
          <Text style={styles.footerText}>
            Clear Health Inc. All rights reserved.
          </Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      {internalUser && (
        <StyledButton
          icon="pi pi-arrow-left"
          onClick={() => {
            navigate(`/orders/${orderInfo.id}`);
          }}
          className="p-button-text p-button-secondary p-0"
        >
          <p className="small-text">Back to Order</p>
        </StyledButton>
      )}
      <PDFViewer width="100%" height="1200px">
        {receipt}
      </PDFViewer>
    </>
  );
}
