import { api } from ".";

const stripeQueries = api.injectEndpoints({
  endpoints: (builder) => ({
    attachPaymentMethod: builder.mutation({
      query: ({ paymentMethodId, customerId }) => ({
        url: "/stripe/attach/payment_methods",
        method: "POST",
        body: {
          paymentMethodId,
          customerId,
        },
      }),
    }),
  }),
});

export const { useAttachPaymentMethodMutation } = stripeQueries;
