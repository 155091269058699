import { LoadingSpinner } from "components/LoadingSpinner";
import { orderStatusConstants } from "constants/order";
import { ToastContext } from "context/ToastContext";
import { sizer } from "layout/styles/styled/sizer";
import { getPaymentPlanEnabled } from "pages/CreateOrder/utils/helpers";
import { Receipt } from "pages/VerificationPage/components/Receipt";
import { Button } from "primereact/button";
import { useContext, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useGetOrderQuery } from "store/queries/order";
import {
  onChangeViewOrderViewReceipt,
  orderSelector,
} from "store/slices/order";
import styled from "styled-components";
import { IOrder } from "types/Order/Order";
import { IPaymentPlan } from "types/Payment";

import CommunicationDetails from "./components/CommunicationDetails/CommunicationDetails";
import { Header } from "./components/OrderHeader/OrderHeader";
import OrderHistory from "./components/OrderHistory/OrderHistory";
import PatientDetails from "./components/PatientDetails/PatientDetails";
import PaymentDetails from "./components/PaymentDetails/PaymentDetails";
import ServiceDetails from "./components/ServiceDetails/ServiceDetails";

const StyledButton = styled(Button)`
  gap: ${sizer(2)};
  margin-bottom: ${sizer(4)};

  span:first-child {
    font-size: ${sizer(3)};
  }
`;

export function OrderViewPage() {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { viewOrderViewReceipt } = useSelector(orderSelector);

  const { data: order, isLoading, error, refetch } = useGetOrderQuery(id);

  const { current: toastElement } = useContext(ToastContext);
  const orderDetails = order?.data as IOrder;

  const showPaymentPlan = useMemo(() => {
    if (!orderDetails) return false;
    const orderStatus = orderDetails?.status;
    const activePaymentPlan = orderDetails.paymentPlan.find((p) => p.active);
    if (
      [
        orderStatusConstants.PAID,
        orderStatusConstants.REFUNDED,
        orderStatusConstants.PARTIALLY_PAID,
      ].includes(orderStatus)
    ) {
      return !!activePaymentPlan?.installments?.length;
    }
    return getPaymentPlanEnabled(
      orderDetails.orderType,
      orderDetails.account.paymentPlanTypeEnabled
    );
  }, [orderDetails]);
  const formInitiator = useForm();

  const paymentPlanEditable = useMemo(() => {
    if (!orderDetails) return false;
    const orderStatus = orderDetails?.status;

    return ![
      orderStatusConstants.PAID,
      orderStatusConstants.CANCELED,
      orderStatusConstants.EXPIRED,
      orderStatusConstants.DELETED,
      orderStatusConstants.REFUNDED,
    ].includes(orderStatus);
  }, [orderDetails]);

  useEffect(() => {
    dispatch(onChangeViewOrderViewReceipt(false));
  }, [id]);

  if (!orderDetails && isLoading) {
    return <LoadingSpinner />;
  }

  if ((error as any)?.originalStatus === 404) {
    toastElement?.show({
      summary: "Error",
      severity: "error",
      detail: "Order not found.",
    });
    navigate("/orders");

    return <LoadingSpinner />;
  }

  return viewOrderViewReceipt ? (
    <div>
      <StyledButton
        icon="pi pi-arrow-left"
        onClick={() => dispatch(onChangeViewOrderViewReceipt(false))}
        className="p-button-text p-button-secondary p-0"
      >
        <p className="small-text">Return</p>
      </StyledButton>

      <Receipt {...{ order: orderDetails }} />
    </div>
  ) : (
    <div className="flex flex-column gap-2">
      <Header {...{ order: orderDetails, onUpdateOrder: () => refetch() }} />
      <FormProvider {...formInitiator}>
        <PatientDetails {...{ order: orderDetails }} />
        <ServiceDetails
          {...{ order: orderDetails, onUpdateOrder: () => refetch() }}
        />
      </FormProvider>

      <div className="flex gap-3 flex-wrap">
        <FormProvider {...formInitiator}>
          <PaymentDetails
            {...{
              payments: orderDetails?.payments,
              paymentPlan: orderDetails?.paymentPlan.find(
                (p) => p.active
              ) as IPaymentPlan,
              orderId: orderDetails.id,
              onUpdateOrder: () => refetch(),
              editable: paymentPlanEditable,
              showPaymentPlan,
              orderStatus: orderDetails.status,
            }}
          />
        </FormProvider>
        <CommunicationDetails {...{ order: orderDetails }} />
      </div>
      <OrderHistory
        {...{
          orderHistories: orderDetails.orderHistories,
          orderCreated: orderDetails.createdAt,
          orderId: orderDetails.id,
        }}
      />
    </div>
  );
}
