import { parseDateInCST, parseTimeInCST } from "helpers/date";
import { centsToDollars, parsePrice } from "helpers/price";
import { sizer } from "layout/styles/styled/sizer";
import { Card } from "primereact/card";
import { Timeline } from "primereact/timeline";
import { useMemo } from "react";
import styled from "styled-components";
import { IOrderHistory } from "types/Order/Order";

const StyledCard = styled(Card)`
  margin-bottom: ${sizer(2)};
`;

const StyledTimeline = styled(Timeline)`
  .p-timeline-event-opposite {
    flex: 0 0;
    min-width: 135px;
    padding-top: 6px !important;
  }
  .p-timeline-event-content {
    padding-top: 6px !important;
  }
`;

const generateOrderHistoryLine = (item: IOrderHistory) => {
  const isPaid = !!(item.type === "Paid");
  const isTransfer = item.type === "Transfer";
  const transferText = ` ${parsePrice(
    centsToDollars(item.amountInCents || 0)
  )} From ${item.fromProvider?.name || "Clear Health"} To ${
    item.toProvider?.name ||
    `${item.toPatient?.firstName} ${item.toPatient?.lastName}`
  }`;
  return (
    <span>
      {parseDateInCST(item.createdAt)} {parseTimeInCST(item.createdAt)}
      {isTransfer && transferText}
      {item.user && !isPaid && ` by ${item.user.name}`}
      {item.user && isPaid && ` Collected by ${item.user.name}`}
      {item.thirdParty && `by ${item.thirdParty}`}
    </span>
  );
};

const custimizedMarker = (item: IOrderHistory) => {
  const iconClasses: { [key: string]: string } = {
    Created: "pi pi-check",
    Updated: "pi pi-pencil",
    Canceled: "pi pi-times",
    Paid: "pi pi-dollar",
    Transfer: "pi pi-sync",
    PartiallyPaid: "pi pi-dollar",
    InstallmentPaid: "pi pi-dollar",
  };
  return (
    <div
      className="flex w-2rem h-2rem align-items-center justify-content-center border-2 border-circle"
      style={{
        borderColor: "var(--color-mint-5)",
        color: "var(--color-mint-5)",
      }}
    >
      <i className={iconClasses[item.type]} />
    </div>
  );
};

const typeRenderer: { [key: string]: string } = {
  PartiallyPaid: "Partially Paid",
  InstallmentPaid: "Auto Paid",
};

function OrderHistory({
  orderHistories,
  orderCreated,
  orderId,
}: {
  orderHistories: IOrderHistory[];
  orderCreated: string;
  orderId: string;
}) {
  const orderHistoryList = useMemo(() => {
    const orderHistoryListUpdated = [...orderHistories];
    const hasCreatedType = orderHistories.some(
      (item) => item.type === "Created"
    );
    if (!hasCreatedType) {
      orderHistoryListUpdated.unshift({
        type: "Created",
        createdAt: orderCreated,
        orderId,
      });
    }
    return orderHistoryListUpdated;
  }, [orderHistories, orderId, orderCreated]);

  return (
    <StyledCard title="Order history">
      <StyledTimeline
        value={orderHistoryList}
        content={(item) => generateOrderHistoryLine(item)}
        opposite={(item) => typeRenderer[item.type] || item.type}
        marker={(item) => custimizedMarker(item)}
      />
    </StyledCard>
  );
}

export default OrderHistory;
