import { orderTypeConstants } from "constants/order";
import dayjs from "dayjs";
import { parsePrice } from "helpers/price";
import { Timeline } from "primereact/timeline";
import { useMemo } from "react";
import styled from "styled-components";
import { OrderType } from "types/Order/OrderType";

import { PriceWrapper } from "../styled";

const StyledTimeline = styled(Timeline)`
  font-size: 13px !important;
  .p-timeline-event-opposite {
    flex: 0 0;
    min-width: 90px;
    padding-top: 0px !important;
    padding-left: 0px !important;
    display: flex;
    justify-content: left;
  }
  .p-timeline-event-content {
    padding-top: 0px !important;
    padding-right: 0px !important;
  }
`;

function PaymentPlan({
  orderInfo,
  fullPrice,
  clearPrice,
}: {
  orderInfo: any;
  fullPrice: number;
  clearPrice: number;
}) {
  const estimatedCost = useMemo(() => {
    const orderTypeAmountMap = new Map<string, number>([
      [orderTypeConstants.BARIATRICS, clearPrice],
      [orderTypeConstants.BUNDLED, clearPrice],
      [orderTypeConstants.PATIENT_RESPONSIBILITY, fullPrice],
      [orderTypeConstants.GFE, fullPrice],
      [orderTypeConstants.ED, clearPrice],
    ]);
    const estimatedCost = orderTypeAmountMap.get(orderInfo.orderType) || 0;

    return estimatedCost;
  }, [fullPrice, clearPrice, orderInfo]);

  const initialPayment = useMemo(() => {
    const activePaymentPlan = orderInfo.paymentPlan.find(
      (plan: any) => plan.active
    );
    const percentage = activePaymentPlan.paymentPlanMinDownPaymentPercent;
    return estimatedCost * (percentage / 100);
  }, [estimatedCost, orderInfo]);

  const paymentPlanDuration = useMemo(() => {
    const activePaymentPlan = orderInfo.paymentPlan.find(
      (plan: any) => plan.active
    );
    return activePaymentPlan.paymentPlanDuration;
  }, [orderInfo]);

  const monthlyPayment = useMemo(() => {
    const remainingAmount = estimatedCost - initialPayment;
    return Math.floor((remainingAmount / paymentPlanDuration) * 100) / 100;
  }, [estimatedCost, initialPayment, paymentPlanDuration]);

  const paymentsToShow = useMemo(() => {
    const remainingAmount = estimatedCost - initialPayment;
    const basePayment =
      Math.floor((remainingAmount / paymentPlanDuration) * 100) / 100;
    const lastPayment =
      remainingAmount - basePayment * (paymentPlanDuration - 1);

    return Array.from({ length: paymentPlanDuration }, (_, index) => {
      const date = dayjs()
        .add(index + 1, "month")
        .format("YYYY-MM-DD");
      const payment =
        index === paymentPlanDuration - 1 ? lastPayment : basePayment;

      return { date, payment: parsePrice(payment) };
    });
  }, [orderInfo, initialPayment]);
  const generateContent = (item: any) => {
    return (
      <div className="w-full flex justify-content-between">
        <p className="">{item.date}</p>
        <p className="text-sm font-semibold text-right">{item.payment}</p>
      </div>
    );
  };

  const customizedMarker = () => {
    return <i className="pi pi-clock green" />;
  };
  return (
    <PriceWrapper className="flex flex-column justify-content-center align-items-center mt-3 mb-3">
      <div className="flex gap-2 w-full mb-4">
        <i className="pi pi-info-circle purple" />
        <h3 className="text-sm purple">
          {orderInfo.patient.firstName} {orderInfo.patient.lastName} You’ve been
          approved for $0 fee, 0% Interest Payment Plan!
        </h3>
      </div>
      <div className="flex justify-content-between w-full">
        <p className="text-xs font-semibold">
          Initial payment is required Today
        </p>
        <p className="text-xl purple font-bold text-right">
          {parsePrice(initialPayment)}
        </p>
      </div>
      <div className="flex flex-column gap-2 w-full mt-2">
        <p className="text-xs font-semibold mb-2">
          +{paymentPlanDuration} payments of {parsePrice(monthlyPayment)} due:
        </p>
        <StyledTimeline
          value={paymentsToShow}
          opposite={(_, i) => `Payment ${i + 1}`}
          content={(item) => generateContent(item)}
          marker={customizedMarker}
        />
      </div>
    </PriceWrapper>
  );
}

export default PaymentPlan;
